<template>
  <div class="container">
    <h3 class="text-center mt-5 mb-5">Журнал событий "Выдача справки"</h3>
    <DataTable :value="referenceLogs" tableStyle="min-width: 50rem" paginator :rows="10"
               :rowsPerPageOptions="[5, 10, 20, 50]" stripedRows responsiveLayout="scroll"
               :globalFilterFields="['fullname']" v-model:filters="filters" filterDisplay="row" :loading="loading">
      <Column field="date_and_time" header="Дата и время"></Column>
      <Column header="Имя пользователя">
        <template #body="{data}">
          {{data.firstname}}
          {{data.lastname}}
        </template>
      </Column>
      <Column field="ip_address" header="IP-адрес"></Column>
      <Column field="browser_id" header="Идентификатор браузера"></Column>
      <Column field="graduate_work_comment_id" header="Идентификатор файла выпускной работы"></Column>
    </DataTable>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {FilterMatchMode, FilterOperator} from "primevue/api";

export default {
  name: "DiplomaReference",
  data() {
    return {
      loading: true
    }
  },
  methods: {
    ...mapActions('diplomaReference', ['GET_REFERENCE_LOGS']),
  },
  watch: {},
  computed: {
    ...mapState('diplomaReference', ['referenceLogs']),
  },
  async mounted() {
    await this.GET_REFERENCE_LOGS();
    this.loading = false
  }
}
</script>

<style scoped>
</style>
